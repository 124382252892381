<template>

  <v-parallax src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="150px">
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h1 class="text-h4 font-weight-thin mb-4">
        <span>Política de devoluciones</span>
      </h1>
    </div>
  </v-parallax>

  <v-container fluid style="max-width: 680px" >

    <h2>Política de devoluciones de <v-btn class="pa-0" size="small" variant="text" href="https://mychip.es" target="_blank">mychip.es</v-btn></h2>
    <br>
    <p>Los plazos de devolución que a continuación se detallan empezarán a contar desde el momento se realice la inscripción al evento:</p>
    <br>
    <ul class="ml-2">
      <li>Como condición principal se aplicarán las condiciones del reglamento del evento en el que se ha inscrito.</li>
      <li>En el caso de que el reglamento no indique nada al respecto no se atenderán devoluciones a menos de 5 días del cierre de las inscripciones.
        En caso de estar dentro del plazo, las comisiones de gestión de devoluciones en este caso serán las siguientes:
        <ul class="ml-4">
          <li>Inscripciones hasta 5€  se descontará 1,00€</li>
          <li>Inscripciones de desde 5,01€ hasta 10,00€  se descontarán 2,00€.</li>
          <li>Inscripciones desde 10,01€ hasta 20,00€ se descontarán 3,00€.</li>
          <li>A partir de 20.01€ se descontará el 4% del importe total de la inscripción con una comisión mínima de 3€.</li>
        </ul>
      </li>
      <li>Las devoluciones se realizaran directamente por el mismo medio de pago utilizado en sus inscripciones.</li>
    </ul>
    <br>
    <p>Para realizar devoluciones de pedidos realizados a través de nuestra web puede tramitarlos solicitándolo bien por <v-btn class="pa-0" size="small" variant="text" href="https://wa.me/+34607763001" target="_blank">WhatsApp (607763001)</v-btn> o bien por <v-btn class="pa-0" size="small" variant="text" href="mailto:inscripciones@mychip.es" target="_blank">correo electrónico (inscripciones@mychip.es)</v-btn>.</p>

  </v-container>



</template>

<script>
import {defineComponent} from 'vue';
import axios from "axios";
import router from "@/router";

export default defineComponent({
  name: 'CompanyView',
  data: () => ({
  }),
  computed: {
    english() {
      return this.$store.getters.language == 'en'
    },
    spanish() {
      return this.$store.getters.language == 'es'
    },
    valencian() {
      return this.$store.getters.language == 'ca'
    },
  }
});
</script>
